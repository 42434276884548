import { useEffect, useRef, useState } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "videojs-mux";
import useAnalyticsEventTracker, { GA_EVENT } from "../hooks/GoogleAnalytics";
import RatingFeedBack from "../Rating/Rating";
import "./MuxVideoPlayer.scss";

export default function MuxVideoPlayer(props: any) {
  const [showRating, setShowRating] = useState(false);
  const videoRef = useRef<any>(null);
  const playerRef = useRef<any>(null);
  const gaEventTracker = useAnalyticsEventTracker(props.categorieTitle);
  const stateKey = "rating:" + props.id;

  useEffect(() => {
    const video = videoRef.current;

    if (video && playerRef.current === null) {
      playerRef.current = videojs(video, {
        sources: [{ src: props.src, type: "application/x-mpegURL" }],
        fill: true,
        responsive: true,
        autoplay: false,
        controlBar: {
          // volumePanel: false
        },
        plugins: {
          mux: {
            debug: false,
            data: {
              env_key: "isu5145b2ecofa9bgdphnjqhp", // required
            },
          },
        },
      });
    }

    const cookieAccepted = localStorage.getItem("cookieAccepted") === "true";

    playerRef.current.bigPlayButton.handleClick = () => {
      playerRef.current.requestFullscreen().catch((err: any) => {
        console.log(`Could not activate full-screen mode: ${err}`);
      });

      if (cookieAccepted) props.gaNewsFeedEventTracker(GA_EVENT.NEWS_FEED, props.newsFeedTitle);

      playerRef.current.currentTime(0);
      playerRef.current.hasStarted(true);
      playerRef.current.play();
      if (cookieAccepted) gaEventTracker(GA_EVENT.STARTED_VIDEO, props.newsFeedTitle);
    };

    playerRef.current.on("fullscreenchange", () => {
      if (!playerRef.current.isFullscreen()) {
        playerRef.current.hasStarted(false);
        playerRef.current.currentTime(0);
        playerRef.current.pause();
        if (cookieAccepted) gaEventTracker(GA_EVENT.PAUSED_VIDEO, props.newsFeedTitle);
      } else {
        playerRef.current.currentTime(0);
        playerRef.current.hasStarted(true);
        playerRef.current.play();
      }
    });

    playerRef.current.on("ended", () => {
      if (playerRef.current.isFullscreen()) {
        playerRef.current.exitFullscreen();
        if (cookieAccepted) gaEventTracker(GA_EVENT.WATCHED_COMPLETE_VIDEO, props.newsFeedTitle);
      }

      playerRef.current.hasStarted(false);

      if (localStorage.getItem(stateKey) !== "true" && props.isRatingActive) {
        setShowRating(true);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoRef, props.src]);

  return (
    <>
      <video controls ref={videoRef} poster={props.thumbnailGif} preload={"auto"} className="vjs-matrix video-js" />
      {showRating && props.isRatingActive && (
        <RatingFeedBack
          id={props.newsFeedTitle}
          showRating={showRating}
          path={'/'} // TODO: unsure about this one
        />
      )}
    </>
  );
}
