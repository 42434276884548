export const StringIsUndefinedNullOrEmpty = (value?: string) => {
  return value === undefined || value === "" || value === null;
};

export const GetImageSrc = (news: any) => {
  return news?.coverImage ? news.coverImage.asset.url : news?.images !== null ? news?.images[0].asset.url : "";
};

export const GetImageAlt = (news: any) => {
  return news?.coverImage ? news.title : news?.images !== null ? news?.images[0].alt : "";
};

export const IsArticle = (news: any) => {
  return (news.images && news.body) || news.body;
};

export const HasImages = (news: any) => {
  return news.coverImage !== null || news.images !== null;
};

export const IsGallery = (news: any) => {
  return news.images && !news.body;
};

export const FormatTime = (time: number) => {
  if (time < 5999) {
    // MM:SS
    var minutes = Math.floor(time / 60);
    var seconds = Math.round(time - minutes * 60);
    let stringMin = "";
    let stringSec = "";
    if (minutes < 10) {
      stringMin = "0" + minutes;
    }
    if (seconds < 10) {
      stringSec = "0" + seconds;
    }
    if (seconds > 10 && seconds < 59) {
      stringSec = "" + seconds;
    }

    return stringMin + ":" + stringSec + "s";
  } else {
    return "Exceeds time";
  }
};

export const VideoSrc = (playbackId: string | undefined) => {
  if (playbackId === undefined) return;
  return `https://stream.mux.com/${playbackId}`;
};

export const VideoThumbnail = (playbackId: string | undefined) => {
  if (playbackId === undefined) return;
  return `https://image.mux.com/${playbackId}/animated.gif?&height=640&width=360&fps=15&start=0&end=2.5`;
};
