import { useLocalStorage } from "@mantine/hooks";
import { useEffect, useState } from "react";
import kengleMann from "../../content/svg/Kenglemann.svg";
import "./SplashScreen.scss";

const Splash = () => {
  const [showImg, setShowImg] = useState(false);

  const [splashScreenShown, setSplashShown] = useLocalStorage<boolean>({
    key: "firstTimeSplashScreen",
    defaultValue: false,
  });

  useEffect(() => {
    if (splashScreenShown) {
      setShowImg(false);
    } else {
      const timer = setTimeout(() => setShowImg(false), 2400);
      setSplashShown(true);
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {showImg && (
        <div className="kengle-splash animate__wait animate__fadeOut">
          <div className="svg-box">
            <img alt="" className="animate__animated animate__bounce animate__tada" src={kengleMann} />
          </div>
        </div>
      )}
    </>
  );
};

export default Splash;
