import dayjs from "dayjs";
import { useEffect } from "react";
import { useQuery } from "react-query";
import Client, { getPosts } from "../components/clients/SanityClient";
import { TAB_TYPE } from "../components/models/EnumTypes";
import News from "../components/models/News";
import NewsFeedItem from "../components/NewsFeed/NewsFeedItem";
import NewsFeedWrapper from "../components/NewsFeed/NewsFeedWrapper";
import "./CustomerServiceTab.scss";

async function getData() {
  return await Client().fetch<Array<News>>(getPosts(TAB_TYPE.CUSTOMER_SERVICE));
}

const CustomerServiceTab: React.FC = () => {
  const { data, error, isError, isLoading } = useQuery<Array<News>>({
    queryKey: TAB_TYPE.CUSTOMER_SERVICE,
    queryFn: getData,
  });

  let news = data;

  useEffect(() => {
    window.scrollTo(0, 0);
    const today = dayjs();

    news?.filter((newsItem) => {
      if (!newsItem.unPublishAt) return true;
      const unpublishDateTime = dayjs(newsItem.unPublishAt ?? "");
      return today < unpublishDateTime;
    });
    // eslint-disable-next-line
  }, []);

  if (isLoading) {
    return <div></div>;
  }

  if (isError) {
    return <>Feil : {error}</>;
  }

  return (
    <>
      {
        <NewsFeedWrapper>
          {news?.map((newsData: News, idx) => (
            <NewsFeedItem key={newsData.id} news={newsData} prevPath={"service"} />
          ))}
        </NewsFeedWrapper>
      }
    </>
  );
};

export default CustomerServiceTab;
