import { NavLink, Outlet } from "react-router-dom";
import { ReactComponent as KengleLogo } from ".././content/svg/Kengle_logo.svg";
import useAnalyticsEventTracker, { GA_EVENT } from "../components/hooks/GoogleAnalytics";
import { ROUTE_TYPE, TAB_TYPE } from "../components/models/EnumTypes";
import "./TopMenu.scss";

const TopMenu: React.FC = () => {
  const gaEventTracker = useAnalyticsEventTracker("Topp menybar");

  const onClickEvent = (eventType: string, tabType: string) => {
    const cookieAccepted = localStorage.getItem("cookieAccepted") === "true";

    if (cookieAccepted) gaEventTracker(eventType, tabType);
  };

  return (
    <div className="testus">
      <div className="k-menu">
        <div className="k-menu-wrapper">
          <NavLink
            to={ROUTE_TYPE.HOME}
            className="k-menu-kengle"
            onClick={() => onClickEvent(GA_EVENT.KENGLE_ICON_HOME, TAB_TYPE.HOME)}
          >
            <KengleLogo className="kengle-logo" />
          </NavLink>
          <div></div>
          <NavLink
            to={ROUTE_TYPE.BAR}
            className={({ isActive }) => (isActive ? "active-link k-menu-item" : "link k-menu-item")}
            onClick={() => onClickEvent(GA_EVENT.PAGE_BAR, TAB_TYPE.BAR)}
          >
            Bar
          </NavLink>
          <NavLink
            to={ROUTE_TYPE.CUSTOMER_SERVICE}
            className={({ isActive }) => (isActive ? "active-link k-menu-item" : "link k-menu-item")}
            onClick={() => onClickEvent(GA_EVENT.PAGE_CUSTOMER_SERVICE, TAB_TYPE.CUSTOMER_SERVICE)}
          >
            Service
          </NavLink>
          <NavLink
            to={ROUTE_TYPE.INFORMATION}
            className={({ isActive }) => (isActive ? "active-link k-menu-item" : "link k-menu-item")}
            onClick={() => onClickEvent(GA_EVENT.PAGE_INFORMATION, TAB_TYPE.INFORMATION)}
          >
            Info
          </NavLink>
        </div>
      </div>
      <div className="k-feed-backdrop"> </div>
      <Outlet />
    </div>
  );
};

export default TopMenu;
