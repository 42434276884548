import { useEffect } from "react";
import { useLocalStorage } from "@mantine/hooks";

interface ICookies {
  currentDate: Date;
}

export const Cookies = (props: ICookies) => {
  const [showCookie, setShowCookie] = useLocalStorage({ key: "showCookie", defaultValue: true });
  const [cookieAccepted, setCookieAccepted] = useLocalStorage({ key: "cookieAccepted", defaultValue: false });
  const [expiryDate, setExpiryDate] = useLocalStorage({ key: "cookieExpires", defaultValue: "" });
  const classAccept = "cookie-confirm";
  const classDecline = "cookie-decline";

  const handleLocalStorage = (cookieAccepted: boolean, cookieExpires: Date) => {
    setShowCookie(false);
    setCookieAccepted(cookieAccepted);

    setExpiryDate(cookieExpires.toLocaleString());

    setShowCookie(false);
  };

  const handleClick = (e: any) => {
    const expires = new Date().setDate(new Date().getDate() + 30);
    const cookieExpires = new Date(expires);

    if (e.target.classList?.contains(classAccept)) handleLocalStorage(true, cookieExpires);
    if (e.target.classList?.contains(classDecline)) handleLocalStorage(false, cookieExpires);
  };

  useEffect(() => {
    const expDate = new Date(expiryDate);

    // if (expDate.getTime() > new Date().getTime()) {
    //   setShowCookie(false);
    // } else if (expDate.getTime() < new Date().getTime()) {
    //   setShowCookie(true);
    // }

    // eslint-disable-next-line
  }, [showCookie, props.currentDate]);

  return showCookie ? (
    <div className="cookie-wrapper">
      <div className="cookie-content">
        <span className="cookie-text">
          Denne nettsiden bruker cookies for å samle nødvendig informasjon for at nettsiden skal fungere og slik at vi
          kan forbedre brukeropplevelsen. Ved å trykke "godta" lagrer vi statistikk om hvordan nettsiden benyttes.
          Samtykket lagres i 30 dager før du blir spurt på nytt om du fortsatt ønsker å godta bruk av cookies.
        </span>
        <div className="button-group">
          <button className={classAccept} onClick={handleClick}>
            Godta
          </button>
          <button className={classDecline} onClick={handleClick}>
            Avslå
          </button>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default Cookies;
