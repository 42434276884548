import { MantineProvider } from "@mantine/core";
import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import { QueryClient, QueryClientProvider } from "react-query";
import { Route, Routes, useLocation } from "react-router-dom";
import "./App.scss";
import ArticleNews from "./components/ArticleNews/ArticleNews";
import ContentWrapper from "./components/ContentWrapper/ContentWrapper";
import Cookies from "./components/Cookies/Cookies";
import GalleryCarousel from "./components/GalleryCarousel/GalleryCarousel";
import { ROUTE_TYPE } from "./components/models/EnumTypes";
import CustomerServiceTab from "./pages/CustomerServiceTab";
import FoodAndDrinkTab from "./pages/FoodAndDrinkTab";
import Home from "./pages/Home";
import InformationTab from "./pages/InformationTab";
import NoPageFound from "./pages/NoPage";
import TopMenu from "./pages/TopMenu";
import { Helmet } from "react-helmet";
import queryConfig from "./other/queryConfig";

const queryClient = new QueryClient(queryConfig);

const App: React.FC = () => {
  const location = useLocation();
  useEffect(() => {
    ReactGA.initialize("G-24X0SNTFJY");
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
  }, [location]);

  const fonts =
    "SuisseIntl-Medium, Helvetica, Arial, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif, Apple Color Emoji, Segoe UI Emoji";
  return (
    <MantineProvider
      theme={{ colorScheme: "dark", fontFamily: fonts, loader: "bars" }}
      withGlobalStyles
      withNormalizeCSS
    >
      <QueryClientProvider client={queryClient}>
        <div id="kengle-root">
          <TopMenu />
          <ContentWrapper>
            <Routes>
              <Route path={ROUTE_TYPE.HOME}>
                <Route index element={<Home />} />
                <Route path={ROUTE_TYPE.BAR} element={<FoodAndDrinkTab />} />
                <Route path={ROUTE_TYPE.CUSTOMER_SERVICE} element={<CustomerServiceTab />} />
                <Route path={ROUTE_TYPE.INFORMATION} element={<InformationTab />} />
                <Route path={ROUTE_TYPE.PICTURE_GALLERY + "/:id"} element={<GalleryCarousel />} />
                <Route path={ROUTE_TYPE.ARTICLE + "/:id"} element={<ArticleNews />} />
                <Route path="*" element={<NoPageFound />} />
              </Route>
            </Routes>
            <Helmet>
              <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
            </Helmet>
            <Cookies currentDate={new Date()} />
          </ContentWrapper>
        </div>
      </QueryClientProvider>
    </MantineProvider>
  );
};

export default App;
