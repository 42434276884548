import dayjs from "dayjs";
import React, { useEffect } from "react";
import { useQuery } from "react-query";
import Client, { getPosts } from "../components/clients/SanityClient";
import { TAB_TYPE } from "../components/models/EnumTypes";
import News from "../components/models/News";
import NewsFeedItem from "../components/NewsFeed/NewsFeedItem";
import NewsFeedWrapper from "../components/NewsFeed/NewsFeedWrapper";
import Splash from "../components/SplashScreen/SplashScreen";
import { ReactComponent as KengleTagline } from "../content/svg/Kengle_tagline.svg";
import "./Home.scss";

async function getData() {
  return await Client().fetch<Array<News>>(getPosts());
}

const Home: React.FC = () => {
  const { data, error, isError, isLoading } = useQuery<Array<News>>({
    queryKey: "Home",
    queryFn: getData,
  });

  let news = data;

  useEffect(() => {
    const todayDateTime = dayjs();
    window.scrollTo(0, 0);
    news = news?.filter((newsItem) => {
      // TODO: DRY. Refactor and reuse
      if (!newsItem.unPublishAt) return true;
      const unpublishDateTime = dayjs(newsItem.unPublishAt ?? "");

      if (newsItem.categories.some((c) => c.title === TAB_TYPE.INFORMATION)) return false;

      return todayDateTime.isBefore(unpublishDateTime);
    });
    // eslint-disable-next-line
  }, []);

  if (isLoading) {
    return <div></div>;
  }

  if (isError) {
    return <>Feil : {error}</>;
  }

  return (
    <>
      <Splash></Splash>
      <div className="kengle-img-background">
        <div className="customer-background">
          <div className="k-top">
            <div className="k-tagline">
              <KengleTagline className="kengle-slogo" />
            </div>
          </div>
        </div>
        {
          <NewsFeedWrapper>
            {news?.map((newsData: News) => (
              <NewsFeedItem key={newsData.id} news={newsData} prevPath={"/"} />
            ))}
          </NewsFeedWrapper>
        }
      </div>
    </>
  );
};

export default Home;
