import Footer from "../Footer/Footer";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import "./ContentWrapper.scss";

type Props = {
  children?: React.ReactNode;
};

const ContentWrapper = ({ children }: Props) => (
  <>
    <div className="kengle-contentwrapper">{children}</div>

    <ScrollToTop />
    <Footer />
  </>
);
export default ContentWrapper;
