import { Divider } from "@mantine/core";
import { memo, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import { animated, useSpring } from "react-spring";
import { ReactComponent as ArticleIcon } from "../../content/svg/article_icon.svg";
import { ReactComponent as GalleryIcon } from "../../content/svg/gallery_icon.svg";
import { ReactComponent as ReadTimeIcon } from "../../content/svg/timestamp_icon.svg";
import useIntersectionObserver from "../../hooks/useIntersectionObserver";
import useAnalyticsEventTracker, { GA_EVENT } from "../hooks/GoogleAnalytics";
import { ROUTE_TYPE, TAB_TYPE } from "../models/EnumTypes";
import News from "../models/News";
import MuxVideoPlayer from "../MuxVideoPlayer/MuxVideoPlayer";
import "./NewsFeed.scss";
import { springConfig } from "../../other/springConfig";
import {
  FormatTime,
  GetImageAlt,
  GetImageSrc,
  HasImages,
  IsArticle,
  IsGallery,
  VideoSrc,
  VideoThumbnail,
} from "../helpers/Helper";

interface INewsFeed {
  news: News;
  prevPath: string;
}

const NewsFeed: React.FC<INewsFeed> = memo(
  (props: INewsFeed) => {
    const navigate = useNavigate();
    const location = useLocation();
    const gaNewsFeedEventTracker = useAnalyticsEventTracker(props.news.categories[0].title);
    const springProps = useSpring(springConfig);
    const hasImages = HasImages(props.news);
    const imageSrc = GetImageSrc(props.news);
    const imageAlt = GetImageAlt(props.news);
    const isArticle = IsArticle(props.news);
    const isGallery = IsGallery(props.news);
    const playbackId = props.news.video?.playbackId;
    const videoSrc = VideoSrc(props.news.video?.playbackId);
    const videoThumbnail = VideoThumbnail(props.news.video?.playbackId);
    const videoDuration = FormatTime(props.news.video?.data?.duration ?? 0);
    const referenceEl = useRef() as React.MutableRefObject<HTMLDivElement>;
    const elementIsIntersecting = useIntersectionObserver(referenceEl);

    useEffect(() => {
      const scrollPosition = localStorage.getItem("scrollPosition");
      const prevUserLocation = localStorage.getItem("prevUserLocation");

      if (scrollPosition && prevUserLocation === location.pathname) {
        window.scrollTo(0, parseInt(scrollPosition));
      } else {
        localStorage.removeItem("scrollPosition");
      }
      // eslint-disable-next-line
    }, [referenceEl, elementIsIntersecting]);

    const setSessionState = () => {
      const cookieAccepted = localStorage.getItem("cookieAccepted") === "true";
      if (cookieAccepted) gaNewsFeedEventTracker(GA_EVENT.NEWS_FEED, props.news.title);

      localStorage.setItem("scrollPosition", window.pageYOffset.toString());
      localStorage.setItem("prevUserLocation", location.pathname);
    };

    const onClickGalleryFeed = () => {
      setSessionState();
      navigate(`${ROUTE_TYPE.PICTURE_GALLERY}/${props.news.id}`, {
        state: { ...props, prevPath: location.pathname },
      });
    };

    const onClickArticleFeed = () => {
      setSessionState();
      navigate(`${ROUTE_TYPE.ARTICLE}/${props.news.id}`, { state: props });
    };

    const getClickFunction = () => {
      if (isArticle) return onClickArticleFeed();
      if (isGallery) return onClickGalleryFeed();
      return null;
    };

    const onClickTag = (e: any) => {
      localStorage.removeItem("scrollPosition");
      localStorage.removeItem("prevUserLocation");
      switch (e) {
        case TAB_TYPE.BAR:
          return navigate(ROUTE_TYPE.BAR);
        case TAB_TYPE.CUSTOMER_SERVICE:
          return navigate(ROUTE_TYPE.CUSTOMER_SERVICE);
        case TAB_TYPE.INFORMATION:
          return navigate(ROUTE_TYPE.INFORMATION);
        default:
          return null;
      }
    };

    return (
      <>
        <animated.div
          style={{ ...springProps }}
          ref={referenceEl}
          className="newsfeed-item-wrapper"
          key={props.news.id}
        >
          {elementIsIntersecting && (
            <div className="newsfeed-item">
              {props.news.categories?.map((tag) => (
                <div className="tag-border" key={tag.title} onClick={() => onClickTag(tag.title)}>
                  <span className="tag-title">{tag.title}</span>
                </div>
              ))}
              <div className="newsfeed-item-inner">
                {hasImages && (
                  <div className="newsfeed-image">
                    {props.news && props.news.body && props.news.timeSpentReading !== null && (
                      <div className="read-time-container">
                        <ReadTimeIcon className="read-time-icon" />
                        <p className="read-time-text">{props.news.timeSpentReading}</p>
                      </div>
                    )}
                    {isArticle && <ArticleIcon onClick={onClickArticleFeed} className="content-icon" />}
                    {isGallery && <GalleryIcon onClick={onClickGalleryFeed} className="content-icon" />}
                    <img className="img-fixed-size" alt={imageAlt} src={imageSrc} loading="lazy" />
                  </div>
                )}
                <>
                  {!hasImages && (
                    <div className="video-container">
                      <div className="read-time-video-container">
                        <ReadTimeIcon className="read-time-icon" />
                        <p className="read-time-text">{videoDuration}</p>
                      </div>
                      <MuxVideoPlayer
                        id={props.news.id}
                        src={videoSrc}
                        thumbnailGif={videoThumbnail}
                        newsFeedTitle={props.news.title}
                        isRatingActive={props.news.rating}
                        categorieTitle={props.news.categories[0]?.title}
                        playbackId={playbackId}
                        gaNewsFeedEventTracker={gaNewsFeedEventTracker}
                        prevPath={props.prevPath}
                      />
                    </div>
                  )}
                </>
                <div className="dark">
                  <div className="newsfeed-title" onClick={getClickFunction}>
                    <span>{props.news.title}</span>
                  </div>
                </div>
              </div>
              <Divider className="divider" my="sm" />
            </div>
          )}
        </animated.div>
      </>
    );
  },
  (prevProps: INewsFeed, nextProps: INewsFeed) => {
    if (JSON.stringify(prevProps.news) === JSON.stringify(nextProps.news)) {
      return true; // props are equal
    }
    return false; // props are not equal -> update the component
  }
);

export default NewsFeed;
