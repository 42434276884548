import { Rating } from "react-simple-star-rating";
import "./Rating.scss";
import { ReactComponent as EmptyStar } from "../../content/svg/Star_outline.svg";
import { ReactComponent as FilledStar } from "../../content/svg/Star_filled.svg";
import { ReactComponent as NoStarSmile } from "../../content/svg/Smile_no-input.svg";
import { ReactComponent as OneStarSmile } from "../../content/svg/Smile_1-star.svg";
import { ReactComponent as TwoStarSmile } from "../../content/svg/Smile_2-stars.svg";
import { ReactComponent as ThreeStarSmile } from "../../content/svg/Smile_3-stars.svg";
import { ReactComponent as FourStarSmile } from "../../content/svg/Smile_4-stars.svg";
import { ReactComponent as FiveStarSmile } from "../../content/svg/Smile_5-stars.svg";
import ReactDOM from "react-dom";
import { useEffect, useState } from "react";
import { animated, useSpring } from "react-spring";
import { PostRatings } from "../clients/ApiClient";
import useAnalyticsEventTracker from "../hooks/GoogleAnalytics";
import { useNavigate } from "react-router";

const RatingFeedBack = (props: any) => {
  const navigate = useNavigate();
  const { id, path } = props;
  const [rating, setRating] = useState(0);
  const gaEventTracker = useAnalyticsEventTracker("Rating");
  const [showRating, setShowRating] = useState(true);
  const stateKey = "rating:" + id;

  const handleRating = (newRating: number) => {
    setRating(newRating);

    (async () => {
      await PostRatings(id, newRating);
    })();

    localStorage.setItem(stateKey, "true");
    setTimeout(() => {
      gaEventTracker("Gave rating ", id, newRating);
      setShowRating(false);

      navigate(path);
    }, 1000);
  };

  const closeRating = () => {
    (async () => {
      await PostRatings(id, 0);
    })();
    gaEventTracker("Gave no rating", id, 0);

    setShowRating(false);

    navigate(path);
  };

  useEffect(() => {
    const localStorageState = localStorage.getItem(stateKey);

    if (localStorageState === "true") {
      setShowRating(false);
    }
    // eslint-disable-next-line
  }, [showRating]);

  const springProps = useSpring({
    from: { opacity: 0, transform: "translate3d(0,25vh,0)" },
    to: { opacity: 1, transform: "translate3d(0,0%,0)" },
  });

  return ReactDOM.createPortal(
    <>
      {showRating && (
        <animated.div className="kengle-rating-feedback" style={{ ...springProps }}>
          <div className="kengle-rating-header">Hvordan likte du dette?</div>
          <div className="kengle-rating-star-body">
            {rating === 0 && <NoStarSmile />}
            {rating === 20 && <OneStarSmile />}
            {rating === 40 && <TwoStarSmile />}
            {rating === 60 && <ThreeStarSmile />}
            {rating === 80 && <FourStarSmile />}
            {rating === 100 && <FiveStarSmile />}
            <div className="kengle-rating-txt-line">
              <p className="bad-rating-txt">Ikke bra</p>
              <p className="good-rating-txt">Veldig bra</p>
            </div>
            <div className="star-div">
              <Rating
                onClick={handleRating}
                ratingValue={rating}
                fillColor={"#272525"}
                fullIcon={<FilledStar />}
                emptyIcon={<EmptyStar />}
                size={60}
              />
            </div>
            <button
              disabled={rating === 0 ? false : true}
              className="kengle-rating-btn"
              type="button"
              onClick={closeRating}
            >
              Ikke nå
            </button>
          </div>
        </animated.div>
      )}
    </>,
    document.body
  );
};

export default RatingFeedBack;
