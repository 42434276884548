import { useEffect, useState } from "react";
import { ReactComponent as ArrowUpIcon } from "../../content/svg/arrow_up.svg";
import "./ScrollToTop.scss";

export default function ScrollToTop() {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, []);

  const onClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return <>{showButton && <ArrowUpIcon className="ScrollToTop-Btn back-to-top" onClick={onClick} />}</>;
}
