import ReactGA from "react-ga4";

export enum GA_EVENT {
  KENGLE_ICON_HOME = "KENGLE ICON HOME",
  PAGE_BAR = "BAR SIDE",
  PAGE_CUSTOMER_SERVICE = "SERVICE SIDE",
  PAGE_INFORMATION = "INFO SIDE",
  NEWS_FEED = "NEWS FEED",
  VIDEO = "MUX VIDEO",
  STARTED_VIDEO = "STARTED VIDEO",
  PAUSED_VIDEO = "PAUSED VIDEO",
  WATCHED_COMPLETE_VIDEO = "WATCHED COMPLETE VIDEO",
  IMAGE_GALLERY = "IMAGE GALLERY",
  ARTICLE = "ARTICLE",
  FEEDBACK = "FEEDBACK",
}

const useAnalyticsEventTracker = (category = "Blog category") => {
  return (action = "GA Enum", label = "Title", value = 0) => {
    ReactGA.event({ category, action, label, value });
  };
};
export default useAnalyticsEventTracker;
