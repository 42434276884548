export const PostRatings = async function (name: string | undefined, vote: number) {
  const uri = process.env.NODE_ENV === "development" ? "http://localhost:4280/api/rating" : "/api/rating";

  const params = {
    name: name,
    vote: vote,
  };
  await fetch(uri, {
    method: "POST",
    body: JSON.stringify(params),
  }).catch((error) => {
    console.log(error);
  });
};
