import "./NewsFeed.scss";

type Props = {
  children?: React.ReactNode;
};

const NewsFeedWrapper = ({ children }: Props) => (
  <>
    <div className="kengle-newsfeed">{children}</div>
  </>
);
export default NewsFeedWrapper;
