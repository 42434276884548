import SanityClient from "@sanity/client";
import { StringIsUndefinedNullOrEmpty } from "../helpers/Helper";

const queryString = `
'categories': categories[]->{title, description},
    'images': gallery.images[] {alt, asset->{url}},
    body,
    ingress,
    'id': slug.current,
    title,
    'video': video.video.asset->,
    rating,
    timeSpentReading,
    'unPublishAt': unPublishedAt,
    'publishAt': publishedAt,
    'customers': customers[]->customerName,
    "coverImage": mainImage {asset->{url}}
`;

export function getPosts(categoryTitle?: string) {
  let parsedCategoryTitleFilter = "";

  // if (categoryTitle === "allExceptInformation")
  //   parsedCategoryTitleFilter = `&& !('Information' in categories[]->title)`;
  if (StringIsUndefinedNullOrEmpty(categoryTitle)) categoryTitle = "";
  else parsedCategoryTitleFilter = `&& '${categoryTitle}' in categories[]->title`;

  return `
  *[_type == 'post' ${parsedCategoryTitleFilter}]
    | order(publishedAt desc) {
    ${queryString}
  }
`;
}

export const getPostById = (id: string) => {
  return `*[_type == 'post' && slug.current == '${id}'] { ${queryString} }`;
};

export default function Client() {
  const dataset = process.env.REACT_APP_SANITY_DATASET;
  const projectId = process.env.REACT_APP_SANITY_PROJECT_ID;

  return SanityClient({ projectId: projectId, dataset: dataset, apiVersion: "2021-10-21", useCdn: true });
}
