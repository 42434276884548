import { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "./GalleryCarousel.scss";
import RatingFeedBack from "../Rating/Rating";
import Client, { getPostById } from "../clients/SanityClient";
import { useNavigate, useParams, useLocation } from "react-router";
import News from "../models/News";
import ReactDOM from "react-dom";
import Path from "../helpers/Redirect";

const GalleryCarousel = () => {
  const [news, setNews] = useState<News>();
  const navigate = useNavigate();
  const config: [News] | any = useLocation();
  const path = Path(news, config);
  const [showRating, setShowRating] = useState(false);
  const params = useParams();
  const stateKey = "rating:" + news?.id;

  const handleClose = () => {
    if (news?.rating && localStorage.getItem(stateKey) !== "true") {
      setShowRating(true);
    }
    if (!news?.rating || localStorage.getItem(stateKey) === "true") {
      navigate(path);
    }
  };

  useEffect(() => {
    if (config.state === null) {
      const id = params.id !== undefined ? params.id : "";

      Client()
        .fetch<[News]>(getPostById(id))
        .then((news: [News]) => {
          setNews(news[0]);
        });
    } else {
      setNews(config.state.news);
    }
    // eslint-disable-next-line
  }, [params.id]);

  return ReactDOM.createPortal(
    <div>
      {!showRating && (
        <button type="button" className="close-gallery-btn" onClick={handleClose}>
          <span className="close-img">X</span>
        </button>
      )}
      {
        <Carousel dynamicHeight={true} showThumbs={false} showStatus={false}>
          {news?.images?.map((pic: any) => (
            <div className="my-slide" key={pic.alt}>
              <img src={pic.asset.url} alt={pic.alt}></img>
              {pic.alt && <p className="legend img-content-txt">{pic.alt}</p>}
            </div>
          ))}
        </Carousel>
      }

      {showRating && news?.rating && (
        <RatingFeedBack id={news?.id} showRating={showRating} path={path} />
      )}
    </div>,
    document.body
  );
};

export default GalleryCarousel;
