export enum TAB_TYPE {
  HOME = "Kengle Logo",
  BAR = "Bar",
  CUSTOMER_SERVICE = "Service",
  INFORMATION = "Info",
}

export enum ROUTE_TYPE {
  HOME = "/",
  BAR = "/bar",
  CUSTOMER_SERVICE = "/service",
  INFORMATION = "/info",
  VIDEO = "/video",
  PICTURE_GALLERY = "/bildegalleri",
  ARTICLE = "/artikkel",
}

export enum NEWS_FEED_TYPE {
  VIDEO = "VIDEO",
  IMG = "IMG",
  ARTICLE = "ARTICLE",
}
