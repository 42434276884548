import KengleGif from "../../content/img/Kengle_payoff-engelsk-animert-cream.gif";
import "./Footer.scss";

const Footer = () => {
  return (
    <div className="kengle-footer">
      <div className="kengle-footer-img">
        <img className="footer-gif" src={KengleGif} alt="kengle footer gif" />
      </div>
      <div className="kengle-copyright">Kengle ©2022</div>
    </div>
  );
};

export default Footer;
