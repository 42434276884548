export const Path = (news: any, config: any): string => {
  // We know and can assume: if video always redirect to main feed on close
  if (news?.video) return "/";

  if (config?.state?.prevPath) return config.state.prevPath;
  else {
    const newPath = news?.categories[0].title.replace(/\s/g, "").toLowerCase();
    return "/" + newPath ?? "";
  }
};

export default Path;
