import { PortableText, PortableTextComponents } from "@portabletext/react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useParams } from "react-router";
import News from "../models/News";
import Client, { getPostById } from "../clients/SanityClient";
import { AiOutlineClockCircle } from "react-icons/ai";
import { FaQuestion, FaInfo, FaFirstAid, FaPhone } from "react-icons/fa";
import { TAB_TYPE } from "../models/EnumTypes";
import { Carousel } from "react-responsive-carousel";
import useAnalyticsEventTracker from "../hooks/GoogleAnalytics";
import RatingFeedBack from "../Rating/Rating";
import MuxVideoPlayer from "../MuxVideoPlayer/MuxVideoPlayer";
import { ReactComponent as ReadTimeIcon } from "../../content/svg/timestamp_icon.svg";
import { Path } from "../helpers/Redirect";
import { animated, useSpring } from "react-spring";
import { springConfig } from "../../other/springConfig";
import { FormatTime, GetImageAlt, GetImageSrc, VideoSrc, VideoThumbnail } from "../helpers/Helper";

const ArticleNews = (props: any) => {
  const navigate = useNavigate();
  const params = useParams();
  const config: [News] | any = useLocation();
  const [news, setNews] = useState<News>();
  const imageSrc = GetImageSrc(news);
  const imageAlt = GetImageAlt(news);
  const [showRating, setShowRating] = useState(false);
  const videoSrc = VideoSrc(news?.video?.playbackId);
  const videoThumbnail = VideoThumbnail(news?.video?.playbackId);
  const gaNewsFeedEventTracker = useAnalyticsEventTracker(news?.categories[0].title);
  const videoDuration = FormatTime(news?.video?.data?.duration ?? 0);
  const stateKey = "rating:" + news?.id;
  const path = Path(news, config);
  const springProps = useSpring(springConfig);

  const components: PortableTextComponents = {
    block: {
      h1: ({ children }) => <h1 className="text-2xl">{children}</h1>,
      blockquote: ({ children }) => <blockquote className="border-l-purple-500">{children}</blockquote>,
      normal: ({ children }) => <div>{children}</div>,
      span: ({ children }) => <div>{children}</div>,
      customHeading: ({ children }) => <h2 className="text-lg text-primary text-purple-700">{children}</h2>,
    },

    types: {
      span: ({ value }) => <div>{value}</div>,
    },
  };

  const clickHandler = () => {
    if (news?.rating && localStorage.getItem(stateKey) !== "true") {
      setShowRating(true);
      return;
    }

    if (!news?.rating || localStorage.getItem(stateKey) === "true") {
      setShowRating(false);
      navigate(path);
    }
  };

  useEffect(() => {
    if (news?.rating && localStorage.getItem(stateKey) !== "true") {
      setShowRating(true);
    }
    if (!news?.rating || localStorage.getItem(stateKey) === "true") {
      setShowRating(false);
    }

    window.scrollTo(0, 0);
    if (config.state === null) {
      const id = params.id !== undefined ? params.id : "";

      if (id === "" || id === null || id === undefined) {
        console.error("Id has no value, unable to fetch post");
        return;
      }

      Client()
        .fetch<[News]>(getPostById(id))
        .then((news: [News]) => {
          setNews(news[0]);
        })
        .catch((err: any) => {
          console.error(err);
        });
    } else {
      setNews(config.state.news);
    }
    // eslint-disable-next-line
  }, [params.id, config, showRating]);

  return (
    <>
      <animated.div style={{ ...springProps }} className="news-article-container">
        {!showRating && (
          <button className="close-article-btn" onClick={clickHandler}>
            <span className="close-img">X</span>
          </button>
        )}
        {news?.images && (
          <Carousel className="inline-gallery" dynamicHeight={true} showThumbs={false} showStatus={false}>
            {news.images.map((pic: any) => (
              <div className="article-slide" key={pic.alt}>
                <img src={pic.asset.url} alt={pic.alt}></img>
                {pic.alt && <p className="legend img-content-txt">{pic.alt}</p>}
              </div>
            ))}
          </Carousel>
        )}
        {!news?.images && news?.coverImage && (
          <>
            <img className="article-image" alt={imageAlt} src={imageSrc} />
            {news?.title && <div className="article-news-header">{news?.title}</div>}
          </>
        )}
        {news && !news?.video && news.timeSpentReading !== null && (
          <div className="short-story-wrapper">
            <div className="short-story">Short story</div>
            <div className="time-spent-reading">{news.timeSpentReading}</div>
            <div className="short-story-icon">
              <AiOutlineClockCircle size={16} />
            </div>
          </div>
        )}
        {!news?.images && !news?.coverImage && news?.video && (
          <div className="container-article-video">
            <MuxVideoPlayer
              id={news.id}
              src={videoSrc}
              thumbnailGif={videoThumbnail}
              newsFeedTitle={news.title}
              isRatingActive={news.rating}
              categorieTitle={news.categories[0]?.title}
              playbackId={news?.video?.playbackId}
              gaNewsFeedEventTracker={gaNewsFeedEventTracker}
            />
          </div>
        )}
        {news?.ingress && <div className="ingress">{news?.ingress}</div>}
        {news?.body && !news.video && (
          <div className="body">
            {news &&
              news?.body &&
              news.body?.map((post: any) => (
                <div key={post._key} className="item">
                  <PortableText key={post._key} value={[post]} components={components} />
                </div>
              ))}
          </div>
        )}
      </animated.div>
      {showRating && <RatingFeedBack id={news?.id} showRating={showRating} path={path} />}
      {news?.categories[0].title === TAB_TYPE.INFORMATION && (
        <div className="news-article-footer">
          <div className="footer-section-main">
            <FaQuestion size={20} />
            <h3>Kontakt din arbeidsleder om du har spørsmål.</h3>
            <span className="footer-section-main-text">
              Er det ting verken du eller din gruppeleder finner svar på kan du kontakte oss på frivilligkontoret, eller ringe dette nummeret: {" "}
              <span className="footer-section-main-number"><a href="tel:+4791530207">915 30 207</a></span>
            </span>
          </div>
          <div className="footer-section-wrapper">
            <div className="footer-section">
              <FaInfo size={20} />
              <h3>Hvis skader eller ordensproblem oppstår, kontakt nærmeste vakt.</h3>
            </div>
            <div className="footer-section">
              <FaFirstAid size={20} />
              <h3>Førstehjelp:</h3>
              <span className="footer-section-text">Saniteten har et eget telt på området.</span>
            </div>
            <div className="footer-section">
              <FaPhone className="footer-phone" size={20} />
              <h3>Viktige telefonnummer:</h3>
              <span className="footer-phone-title">Sikkerhet: </span>
              <span className="footer-phone-number"><a href="tel:+4797880831">978 80 831</a></span>
              <span className="footer-phone-title">Frivilligleder: </span>
              <span className="footer-phone-number"><a href="tel:+4791530207">915 30 207</a><br /><a href="tel:+4795056896">950 56 896</a></span>
              <span className="footer-phone-title">Ledelse: </span>
              <span className="footer-phone-number"><a href="tel:+4793066869">930 66 869</a></span>
              <span className="footer-phone-title">Trivselsverter: </span>
              <span className="footer-phone-number"><a href="tel:+4741610361">416 10 361</a></span>
              <span className="footer-phone-title">Transport: </span>
              <span className="footer-phone-number"><a href="tel:+4797132461">971 32 461</a></span>
            </div>
            <div className="footer-section-numbers"></div>
          </div>
        </div>
      )}
    </>
  );
};

export default ArticleNews;
